<template>
    <div class="font-poppin">
        <div class="filter-header">
            <!-- <div class="fs-14 font-weight-bold ml-4">Filters (<span style="color:#ffba00 !important;">{{totalFilters}}</span>)</div> -->
            <div class="fs-14 font-weight-bold ml-4">Filters (0)</div>
            <div class="pointer">
                <span @click="update()">
                    <img class="filter-Images" src="/static/images/filter_check_blue.svg" style="width: 35px;" alt="arrow">
                </span>
                <span @click="resetFilter()">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/ResetBgBlue.svg" alt="reset">
                </span>
                <span @click="closeFilters()">
                    <img class="filter-Images" src="https://cdn.digicollect.com/cdn/scmnew/images/scm/CloseBgBlue.svg" alt="close">
                </span>
            </div>
        </div>
        <div class="container filter-content">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="group-setup mt-6">
                        <div class="fs-14 text-secondary font-weight-bold">Board Member Name</div>
                        <multiselect :limit="1" :loading="isLoading" :limit-text="limitConsultantName" 
                            v-model="temp_consultant_name"
                            :show-labels="false" style="background-color: #e6ecff !important;"
                            track-by="id" :blockKeys="['Delete']" placeholder="Select Consultant Name" 
                            class="diginew-multiselect diginew-multiselect-underline mt-2" 
                            :options="department_name_options" :multiple="true" :searchable="true" :internal-search="true" 
                            :clear-on-select="false" :close-on-select="true" :options-limit="3000" :max-height="200" 
                            :show-no-results="true" :hide-selected="false" >
                            <template slot="tag" slot-scope="{ option }">
                                <span class="custom__tag">
                                <span class="filterSelectedName">{{ option }}</span>
                                    <span class="custom__remove pointer">
                                        <i class="icon icon-close fs-16 text-white"></i>
                                    </span>
                                </span>
                            </template>
                            <!-- <template slot="afterList">
                                <div v-observe-visibility="reachedEndOfList" />
                            </template> -->
                            <template slot="noResult">
                                <div class="text-secondary text-center fw-600">No Results Found</div>
                            </template>
                        </multiselect>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="group-setup mt-6">
                        <div class="fs-14 text-secondary font-weight-bold">Time Period</div>
                        <date-picker class="profile-date-picker calendar-edit-per input-field-underline mt-2 pt-0 pb-0 pr-0 w-100" style="background-color: #e6ecff !important;" v-model="temp_time_period" value-type="format" lang="en" :confirm="true" 
                        :clearable="false"
                        :editable="false"
                        type="date" format="DD/MM/YYYY"></date-picker>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default({
    data(){
        return{
            temp_consultant_name: [],
            temp_time_period: '',
            department_name_options: [],
            isLoading:false
        }
    },
    methods: {
        limitConsultantName(count) {
			return `+ ${count} more`
        },
        closeFilters(){
            this.hide_filters = false
            this.$emit('hideFilters')
        },
    }
})

</script>


<style scoped>
    .filter-content{
        overflow:hidden;
    }
</style>